import React, { useState, useEffect } from 'react'
import axios from 'axios'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { get } from 'idb-keyval'

import './styles.scss'

import PhoneSVG from './PhoneSVG'
import WhatsAppSVG from './WhatsAppSVG'
import { SearchStoreNavigationProductPage } from './SearchStoreNavigationProductPage'

type DataRegionalizationProps = {
  state: string
  city: string
}

type ListStoresProps = {
  dataRegionalization: DataRegionalizationProps
  allStores: Store[]
  currentlySelectedStores: Store[]
  setCurrentlySelectedStores: React.Dispatch<React.SetStateAction<Store[]>>
  setIsResults: React.Dispatch<React.SetStateAction<boolean>>
}

type RenderSearchStoreProps = {
  props: {
    dataRegionalization: DataRegionalizationProps
    allStores: Store[]
    currentlySelectedStores: Store[]
    isResults: boolean
    currentStateCities: string[]
    orderedStates: string[]
    setCurrentStateCities: React.Dispatch<React.SetStateAction<string[]>>
    setCurrentlySelectedStores: React.Dispatch<React.SetStateAction<Store[]>>
    setIsResults: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export interface Store {
  estado: string
  cidade: string
  loja: string
  logradouro: string
  telefone: string
  obs: string
  whatsapp: string
}

function scrollToTop(id: string) {
  if (id && id !== '') {
    const elToScroll = document?.getElementById(id)

    if (elToScroll) {
      window.scroll({ top: 0 })
      let sizeTop = 150

      const headerHeight = document
        ?.querySelector('.header-content-wrapper')
        ?.getBoundingClientRect().height

      const topBarHeight = document
        ?.querySelector('.topbar-wrapper-vivara')
        ?.getBoundingClientRect().height

      if (headerHeight && topBarHeight) {
        sizeTop = headerHeight + topBarHeight
      }

      const y = elToScroll?.getBoundingClientRect().top

      setTimeout(() => {
        window.scrollTo({ top: y - sizeTop, behavior: 'smooth' })
      }, 1000)
    }
  }
}

const getCep = async () => {
  return navigator?.cookieEnabled && get('main::store::regionData')
}

const fetchData = (
  setDataRegionalization: React.Dispatch<
    React.SetStateAction<DataRegionalizationProps>
  >
) => {
  getCep()
    .then((res) => {
      setDataRegionalization(res)
    })
    .catch((err) => console.error('Error in getCep -  SearchStore', err))
}

const reOrderFunction = (a: string, b: string) => {
  return a?.localeCompare(b)
}

const findStateStores = (
  state: string,
  allStores: Store[],
  setCurrentStateCities: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const currentStateStores = allStores?.filter(
    (store: Store) => store.estado === state
  )

  const unfilteredCurrentStateCities: string[] = currentStateStores?.map(
    (store: Store) => store.cidade
  )

  const filteredCurrentStateCities = unfilteredCurrentStateCities?.filter(
    (ele, pos) => unfilteredCurrentStateCities.indexOf(ele) === pos
  )

  filteredCurrentStateCities.sort(reOrderFunction)

  setCurrentStateCities(filteredCurrentStateCities)
}

const listStores = ({
  dataRegionalization,
  allStores,
  currentlySelectedStores,
  setCurrentlySelectedStores,
  setIsResults,
}: ListStoresProps) => {
  const selectedCityElement = document.getElementById(
    'city'
  ) as HTMLSelectElement

  const stateHref = decodeURI(window?.location?.href?.split('=')[2])?.split(
    '&'
  )[0]

  const selectedCity =
    selectedCityElement?.options[selectedCityElement?.selectedIndex]?.value ??
    stateHref ??
    dataRegionalization?.city

  const cityStores = allStores?.filter(
    (store: Store) => store.cidade === selectedCity
  )

  setCurrentlySelectedStores(cityStores)
  setIsResults(currentlySelectedStores.length > 0)
  scrollToTop('results')
}

const RenderSearchStore = (props: RenderSearchStoreProps) => {
  const { isMobile } = useWindowDimensions()
  const [renderOrderedStates, setRenderOrderedStates] = useState(false)

  const {
    dataRegionalization,
    allStores,
    currentlySelectedStores,
    isResults,
    currentStateCities,
    orderedStates,
    setCurrentStateCities,
    setCurrentlySelectedStores,
    setIsResults,
  } = props.props

  const listStoreObject = {
    dataRegionalization,
    allStores,
    currentlySelectedStores,
    setCurrentlySelectedStores,
    setIsResults,
  }

  if (currentlySelectedStores.length > 0) {
    setIsResults(currentlySelectedStores.length > 0)
  }

  return (
    <div
      className={`container ${
        !isMobile && isResults ? 'containerSuccess' : ''
      }`}
    >
      <section
        className={`sectionBoxSelect ${
          isResults ? 'sectionBoxSelectSuccess' : ''
        }`}
      >
        <div className="containerForm">
          <div className="containerTextForm">
            <h2 className="title">Nossas Lojas</h2>
            <h4 className="subtitle">
              encontre a vivara ou life mais próxima de você
            </h4>
            <hr className="HRBorder" />
          </div>
          <div className="containerSelectAndButton">
            <div className="containerSelect">
              <div className="selectDiv">
                <span className="text">
                  Estado <span>*</span>
                </span>
                <label className="select">
                  <select
                    onClick={() => {
                      setRenderOrderedStates(true)
                    }}
                    onChange={(e) => {
                      findStateStores(
                        e.target?.value,
                        allStores,
                        setCurrentStateCities
                      )
                    }}
                    id="state"
                  >
                    <option value="">Selecione</option>
                    {renderOrderedStates && (
                      <>
                        {orderedStates?.map((state: string) => {
                          return (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          )
                        })}
                      </>
                    )}
                  </select>
                </label>
              </div>
              <div className="selectDiv">
                <span className="text">
                  Cidades <span>*</span>
                </span>
                <label className="select">
                  <select id="city">
                    <option value="">Selecione</option>
                    {currentStateCities?.map((city: string) => {
                      return (
                        <option key={city} value={city} id="city">
                          {city}
                        </option>
                      )
                    })}
                  </select>
                </label>
              </div>
            </div>
            <div className="buttonContainer">
              <button
                className="button"
                onClick={() => listStores(listStoreObject)}
              >
                Encontrar Lojas
              </button>
            </div>
          </div>
        </div>
      </section>
      {isResults ? (
        <section className="sectionBoxResults" id="results">
          <div className="result">
            <span>
              Resultado da busca por: {isMobile && <br />}
              {currentlySelectedStores?.[0]?.estado} -{' '}
              {currentlySelectedStores?.[0]?.cidade}
            </span>
          </div>
          <div className="containerResult">
            {currentlySelectedStores?.map((store: Store) => (
              <ul className="storeList" key={store.loja}>
                <li className="store">{store.loja}</li>
                <li className="address">{store.logradouro}</li>
                <li className="obs" value="#">
                  {store.obs}
                </li>
                <li className="store-link">
                  <SearchStoreNavigationProductPage store={store} />
                </li>
                <li className="contactLi">
                  {store.telefone && (
                    <div className="contact">
                      <PhoneSVG />
                      <span
                        style={{ marginLeft: '1rem', verticalAlign: 'super' }}
                      >
                        {store.telefone}
                      </span>
                    </div>
                  )}
                  {store.whatsapp && (
                    <button
                      onClick={() => {
                        window.open('https://api.whatsapp.com/send/?phone=5511987770045&text=Ol%C3%A1!%20Eu%20gostaria%20de%20iniciar%20o%20meu%20%20o%20atendimento%20&app_absent=0', '_blank')
                      }}
                      className="whatsapp-button-anchor"
                    >
                      <div className="whatsapp">
                        <div className="whatsapp-icon">
                          <WhatsAppSVG />
                        </div>
                        <span className="whatsapp-button-text">WHATSAPP</span>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
            ))}
          </div>
        </section>
      ) : (
        <></>
      )}
    </div>
  )
}

function SearchStore() {
  const [allStores, setAllStores] = useState<Store[]>([])
  const [orderedStates, setOrderedStates] = useState<string[]>([])
  const [currentStateCities, setCurrentStateCities] = useState<string[]>([])
  const [isResults, setIsResults] = useState<boolean>(false)
  const [dataRegionalization, setDataRegionalization] =
    useState<DataRegionalizationProps>({ state: '', city: '' })

  const [currentlySelectedStores, setCurrentlySelectedStores] = useState<
    Store[]
  >([])

  const searchStore = async () => {
    try {
      const response = await axios.get('/api/getSearchStore', {
        headers: {
          'Content-Type': 'aplication/json',
        },
      })

      if (!response.data) {
        return
      }

      const { data } = response

      const unfilteredStates: string[] = data?.map(
        (store: Store) => store.estado
      )

      const filteredStates = unfilteredStates?.filter(
        (ele, pos) => unfilteredStates?.indexOf(ele) === pos
      )

      filteredStates.sort(reOrderFunction)

      setOrderedStates(filteredStates)

      setAllStores(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (allStores.length === 0) {
      searchStore().catch((error) => console.error(error))
    }

    if (!dataRegionalization?.state && !dataRegionalization?.city) {
      return
    }

    const state = decodeURI(window?.location?.href?.split('=')[3])?.split(
      '&'
    )[0]

    const listStoreObject = {
      dataRegionalization,
      allStores,
      currentlySelectedStores,
      setCurrentlySelectedStores,
      setIsResults,
    }

    let stateOrDataRegionalization = dataRegionalization.state

    if (window?.location?.href?.split('=')[3] !== undefined) {
      stateOrDataRegionalization = state
    }

    findStateStores(
      stateOrDataRegionalization,
      allStores,
      setCurrentStateCities
    )
    listStores(listStoreObject)
  }, [dataRegionalization, allStores])

  useEffect(() => {
    fetchData(setDataRegionalization)
  }, [])

  useEffect(() => {
    if (!window || allStores.length === 0) {
      return
    }

    const isRedirect = new URLSearchParams(window.location?.search).get(
      'redirect'
    )

    const cityHref = decodeURI(window?.location?.href?.split('=')[2])?.split(
      '&'
    )[0]

    const currentlySelectedCity =
      cityHref ??
      dataRegionalization?.state ??
      new URLSearchParams(window.location?.search).get('city') ??
      ''

    const currentlySelectedState = ''

    if (!isRedirect) {
      return
    }

    window.scrollTo(0, 0)

    const redirectedStores = allStores?.filter(
      (store: Store) => store.cidade === currentlySelectedCity
    )

    const stateElement = document.getElementById('state') as HTMLSelectElement

    stateElement.value = currentlySelectedState

    findStateStores(currentlySelectedState, allStores, setCurrentStateCities)

    setCurrentlySelectedStores(redirectedStores)
    setIsResults(currentlySelectedStores.length > 0)
  }, [allStores])

  const renderListStoreObject = {
    dataRegionalization,
    allStores,
    currentlySelectedStores,
    isResults,
    currentStateCities,
    orderedStates,
    setCurrentStateCities,
    setCurrentlySelectedStores,
    setIsResults,
  }

  return <RenderSearchStore props={renderListStoreObject} />
}

export default SearchStore
